
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';
import { User } from '@/interfaces/models/User';
import { Venue } from '@/interfaces/models/Venue';

@Component
export default class CampaignFilter extends Vue {
  @Prop({ type: Array, required: true }) public customerGroups!: CustomerGroupEnum[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Object }) public user!: User;
  @Prop({ type: Object }) public venue!: Venue;

  public selectedVenue: string | null = '';
  public selectedCustomerGroup: string = '';

  get accessibleCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }

    return this.user.customerGroup ? [this.user.customerGroup] : [];
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    } else {
      this.setDefaultFilterValues();
    }
  }

  public setDefaultFilterValues() {
    if (this.user.customerGroup) {
      this.selectedCustomerGroup = this.user.customerGroup || '';
    }

    if (!this.selectedCustomerGroup) {
      this.selectedVenue = this.venue._id;
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      this.setDefaultFilterValues();
    }
  }

  @Watch('selectedCustomerGroup')
  public onCustomerGroupFilterChange() {
    if (!this.selectedCustomerGroup && !this.selectedVenue) {
      this.setDefaultFilterValues();
      return;
    }

    if (this.selectedCustomerGroup) {
      this.selectedVenue = '';
      return this.$emit('filter', { customerGroup: this.selectedCustomerGroup });
    }
  }

  @Watch('selectedVenue')
  public onVenueFilterChange() {
    if (this.selectedVenue) {
      this.selectedCustomerGroup = '';
      return this.$emit('filter', { venue: this.selectedVenue });
    }
  }
}
